import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import vinay from "../../Images/Hon'ble Persons/Dr. Vinay M. Agrawal.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";
const PrivacyPolicy = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Row>
          <Col lg={9} className="m-auto">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="linkabout123">Privacy Policy</h2>

            <p className="text-justify">
              ISBM University Privacy Policy outlines the commitment for
              protecting the personal information of its students, members of
              the faculty, staff, and other stakeholders. This policy ensures
              that all personal data is collected, stored, and processed in
              compliance with applicable data protection regulations. The
              University recognizes the importance of privacy and is dedicated
              to safeguard sensitive information such as names, addresses,
              identification numbers, academic records, financial details,
              employment details and any other personal identifiers. The
              collection of personal data is limited the extent of necessary for
              academic, administrative, and operational purposes. The University
              employs strict security measures to prevent unauthorized access,
              alteration, or disclosure of personal information. Personal
              information may be shared with third parties, such as Government
              authorities or service providers, only when legally required or
              with explicit consent. The University also ensures that data is
              not transferred or shared without proper safeguard, adhering to
              international privacy standards wherever applicable. The
              University regularly reviews and updates its Privacy Policy to
              align with changing regulations and technological advancements. By
              doing so, it ensures continued compliance with legal standards and
              maintains the trust of its community. The policy underscores the
              University's commitment to confidentiality, privacy rights, and
              the ethical handling of personal data in all academic and
              administrative functions.
            </p>
            <p className="text-justify">
              One can visit our website <a href="https://isbmuniversity.edu.in">
                isbmuniversity.edu.in
              </a>  without revealing any personal information wherever permissible.
              Certain transactions are required for the submission of personal
              information like profile updates and certain databases. We will
              not sell, swap or rent, or otherwise disclose to any third party
              any personal information for commercial purpose and such
              information will be utilized only for the purpose stated. To
              accomplish such purpose, we may disclose the information to our
              employees, consultants and other concerned having a genuine need
              to know the information.
            </p>
            <p className="text-justify">
              When you engage with our platform, we may collect and store the
              personal information you provide to us from time to time. This
              information helps us tailor our services and enhance your
              experience. You can explore certain areas of our platform without
              revealing your identity or registering. However, once you
              voluntarily provide your personal details, such as your name,
              contact information, or payment details, you will no longer be
              anonymous to us.
            </p>
            <p className="text-justify">
              Our web server may record the numerical Internet Protocol (IP)
              address of the computer you are using, Information about your
              browser and operating system, date and time of access and page
              which linked you to our website. This information may be used to
              administer and improve our website and to generate aggregate
              statistical reports and other similar purpose.
            </p>
            <p className="text-justify">
              We may use cookies and pixels or transparent GIF files to track
              session information and/or to deliver customizable and
              personalized services and information and/or for other purposes of
              website such information is anonymous and not personally
              identifiable. While we make our best efforts to protect the
              privacy of users however we cannot ensure or warrant the security
              of any information you transmit to us, and you do so at your own
              risk.
            </p>
            <p className="text-justify">
              This privacy policy may be revised/modified/amended at any point
              of time at the sole discretion of the University.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
