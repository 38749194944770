import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";
const TermsCondition = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Terms and Conditions</h1>
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Row>
          <Col lg={9} className="m-auto">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="linkabout123">Terms and Conditions</h2>
            <h5 class="fw-bold text-decoration-underline">Introduction</h5>
            <p className="text-justify">
              Welcome to the official website of the{" "}
              <b>
               ISBM University.
              </b>{" "}
              By accessing or utilizing this website, you agree to comply with
              and be bound by the following terms and conditions, which govern
              your relationship with ISBM University concerning this website. Please review
              these terms carefully before using the website.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Acceptance of Terms
            </h5>
            <p className="text-justify">
              By continuing to access or use this website, you acknowledge that
              you have read, understood, and agree to be bound by these terms
              and conditions, as well as any applicable laws and regulations. If
              you do not agree with any of these terms, you are advised to
              refrain from using the website.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Modifications to Terms
            </h5>
            <p className="text-justify">
              ISBM University reserves the right to amend or revise these terms and
              conditions at its sole discretion and at any time. Any
              modifications will become effective immediately upon being posted
              on the website. It is your responsibility to review these terms
              periodically. Continued use of the website following the posting
              of changes signifies your acceptance of those changes.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Eligibility Criteria
            </h5>
            <p className="text-justify">
              Access to certain areas of the website or to specific services
              provided by ISBM University may be restricted to users who meet the
              eligibility criteria, including age requirements and compliance
              with local regulations. By using this website, you confirm that
              you meet such eligibility criteria and that you have the legal
              capacity to agree to these terms.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Admissions and Enrollment
            </h5>
            <p className="text-justify">
              Admission to ISBM University and participation in its academic programs are
              subject to the institution&#39;s admissions policies, which are
              periodically reviewed and updated. These policies outline the
              qualifications, application process, and criteria for acceptance.
              Enrollment in any program or course offered by ISBM University does not
              guarantee completion or certification unless all academic and
              administrative requirements are fulfilled.
            </p>
            <h5 class="fw-bold text-decoration-underline">Use of Website</h5>
            <p className="text-justify">
              This website is provided for educational and informational
              purposes only. You agree to use the website in a manner that is
              lawful and in compliance with these terms. Misuse of the website,
              including but not limited to the dissemination of harmful content,
              unauthorized access, or interference with the site’s operation, is
              strictly prohibited.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Intellectual Property Rights
            </h5>
            <p className="text-justify">
              All intellectual property rights, including but not limited to
              copyrights, trademarks, service marks, logos, and design elements
              displayed on the website, are owned by or licensed to ISBM University. The
              content on this website is protected under applicable intellectual
              property laws. Any unauthorized use, reproduction, or distribution
              of materials from this website is strictly prohibited and may
              result in legal action.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              User-Generated Content
            </h5>
            <p className="text-justify">
              If the website allows you to submit or post content, such as
              comments or media, you retain ownership of the intellectual
              property rights in that content. However, by submitting such
              content, you grant ISBM University a worldwide, non-exclusive, royalty-free
              license to use, distribute,reproduce, modify, adapt, and publicly
              display that content. You are solely responsible for any content
              you submit, and you agree not to post any material that is
              unlawful, offensive, or infringes on third-party rights.
            </p>
            <h5 class="fw-bold text-decoration-underline">External Links</h5>
            <p className="text-justify">
              This website may include links to external websites or third-party
              resources for the convenience of users. ISBM University does not endorse or
              assume any responsibility for the content, products, or services
              available on third-party websites. You acknowledge that any
              interaction with third-party websites is solely at your own risk,
              and ISBM University is not liable for any damages or losses incurred.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Disclaimer of Warranties
            </h5>
            <p className="text-justify">
              ISBM University strives to ensure the accuracy and reliability of the
              information presented on this website. However, the website and
              all content are provided &quot;as is&quot; and &quot;as
              available,&quot; without any express or implied warranties. ISBM University
              does not warrant that the website will be uninterrupted,
              error-free, or free from viruses or other harmful components. Use
              of the website is at your own risk.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Limitation of Liability
            </h5>
            <p className="text-justify">
              To the fullest extent permitted by law, ISBM University disclaims all
              liability for any direct, indirect, incidental, consequential, or
              punitive damages arising from or related to your use of the
              website, including but not limited to errors, omissions,
              interruptions, defects, or delays in operation. Your sole remedy
              for dissatisfaction with the website is to discontinue its use.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Privacy and Data Protection
            </h5>
            <p className="text-justify">
              ISBM University is committed to safeguarding your privacy. Any personal
              information collected through this website will be processed in
              accordance with our [Privacy Policy](link to privacy policy). By
              using this website, you consent to the collection, use, and
              sharing of your data as outlined in the policy.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Governing Law and Jurisdiction
            </h5>
            <p className="text-justify">
              These terms and conditions are governed by and construed in
              accordance with the laws of Maharashtra Jurisdiction. Any disputes
              arising out of or relating to these terms, or the use of the
              website, shall be subject to the exclusive jurisdiction of the
              courts located in [Mumbai/Maharashtra/India].
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Termination of Access
            </h5>
            <p className="text-justify">
              ISBM University reserves the right to terminate or suspend your access to the
              website or any part thereof at any time, without notice, for any
              reason, including but not limited to your violation of these terms
              and conditions or any applicable law.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              Contact Information
            </h5>
            <p className="text-justify">
              For any inquiries or concerns regarding these terms and
              conditions, please contact us at: Email: support@isbm.org.in
              Phone: +918928111111
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default TermsCondition;
