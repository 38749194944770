import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";

function LPEnquireForm() {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [levels, setLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isThankYouVisible, setIsThankYouVisible] = useState(false);

  useEffect(() => {
    // Fetch States
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_states.php",
      dataType: "json",
      success: function (response) {
        if (response?.states) setStates(response.states);
      },
      error: function (error) {
        console.error("Error fetching states:", error);
      },
    });

    // Fetch Levels
    const entity = "ISBMU";
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_levels.php",
      data: { entity },
      dataType: "json",
      success: function (response) {
        if (response?.levels) setLevels(response.levels);
      },
      error: function (error) {
        console.error("Error fetching levels:", error);
      },
    });
  }, []);

  const fetchCities = (state) => {
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_cities.php",
      data: { state },
      dataType: "json",
      success: function (response) {
        if (response?.cities) setCities(response.cities);
      },
      error: function (error) {
        console.error("Error fetching cities:", error);
      },
    });
  };

  const fetchCourses = (level) => {
    const entity = "ISBMU";
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_courses.php",
      data: { level, entity },
      dataType: "json",
      success: function (response) {
        if (response?.courses) setCourses(response.courses);
      },
      error: function (error) {
        console.error("Error fetching courses:", error);
      },
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Show preloader
  
    const form = event.target;
    const formData = new FormData(form);
  
    fetch(form.action, {
      method: form.method,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsSubmitting(false); // Hide preloader after receiving response
  
        if (data.status === "success") {
          setIsThankYouVisible(true);
  
          // Show Thank You message for 5 seconds, then reload the page
          setTimeout(() => {
            // setIsThankYouVisible(false); // Hide Thank You message
            window.location.reload(); // Reload the current page
          }, 5000);
        } else {
          alert("Form submission failed: " + data.message);
        }
      })
      .catch((error) => {
        setIsSubmitting(false); // Hide preloader on error
        alert("An error occurred: " + error.message);
      });
  };
  
  return (
    <div className="enquireForm gradient-border col-xl-3 col-lg-4 col-md-6 col-sm-12 p-0">
    <h5>Enquire Now</h5>
  
   {/* Conditional Rendering */}
   {isSubmitting ? (
      // Preloader
      <div style={{ textAlign: "center" }}>
        <p>Your data is being submitted...</p>
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    ) : isThankYouVisible ? (
      // Thank You Modal
      <div className="thank-you-modal text-center p-4 form-thank-you-div">
        <i className="fas fa-thumbs-up fa-5x text-succeed mb-3"></i>
        <p>Thank You</p>
        <p>Your submission was successful.</p>
      </div>
    ) : (
      // Form
      <form
        action="https://isbmuniversity.edu.in/submitLead.php"
        method="post"
        className="p-4"
        onSubmit={handleFormSubmit}
      >
        <div className="form-group">
          <input
            type="text"
            className="form-control mt-4 mb-3"
            id="name"
            name="name"
            placeholder="Enter your Name"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control mb-3"
            id="email"
            name="email"
            placeholder="Enter your Email"
            required
          />
        </div>
        <div className="form-group mb-3">
          <input
            type="number"
            max="9999999999"
            className="form-control"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            required
          />
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="state"
            name="state"
            required
            onChange={(e) => fetchCities(e.target.value)}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select className="form-select" id="city" name="city" required>
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="level"
            name="level"
            required
            onChange={(e) => fetchCourses(e.target.value)}
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select className="form-select" id="course" name="course" required>
            <option value="">Select Course</option>
            {courses.map((course) => (
              <option key={course} value={course}>
                {course}
              </option>
            ))}
          </select>
        </div>
        <input type="hidden" name="current_url" value={window.location.href} />
        <input
          type="hidden"
          name="utm_source"
          value={
            new URLSearchParams(window.location.search).get("utm_source") || ""
          }
        />
        <input
          type="hidden"
          name="utm_medium"
          value={
            new URLSearchParams(window.location.search).get("utm_medium") || ""
          }
        />
        <input
          type="hidden"
          name="utm_campaign"
          value={
            new URLSearchParams(window.location.search).get("utm_campaign") || ""
          }
        />
        <div className="form-group d-flex justify-content-center">
          <input
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-primary"
          />
        </div>
      </form>
    )}
  </div>
  );
}

export default LPEnquireForm;
