import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import axios from "axios";
import $ from "jquery";
import EnquiryNow from "../common/EnquiryNow3";
import CourseList from "./CourseList";

const Onlinepayment = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getData(e) {
    e.preventDefault();

    var name = $("#name1").val();
    var email = $("#email1").val();
    var amount = $("#amount1").val();
    var phone = $("#phone1").val();
    var course = $("#course1").val();
    var comment = $("#comment1").val();
    var orderid = Date.now();
    let input = {
      email: email,
      name: name,
      amount: amount,
      return_url: "https://isbmuniversity.edu.in/api/payment_response.php",
      order_id: orderid,
      mode: "LIVE",
      currency: "INR",
      description: "Payment Gateway",
      phone: phone,
      address_line_1: "Lalji Pada",
      address_line_2: "Kandivali West",
      city: "Mumbai",
      state: "Maharashtra",
      zip_code: "400051",
      country: "India",
      udf1: course,
      udf2: comment,
      udf3: "",
      udf4: "",
      udf5: "",
    };

    console.log(input);
    await axios
      .post("https://isbmuniversity.edu.in/api/payment_proceed.php", input)
      .then((response) => {
        $('input[name="amount"]').val(amount);
        $('input[name="name"]').val(name);
        $('input[name="email"]').val(email);
        $('input[name="phone"]').val(phone);
        $('input[name="order_id"]').val(orderid);
        $('input[name="udf1"]').val(course);
        $('input[name="udf2"]').val(comment);
        $('input[name="hash"]').val(response.data.data);
        $("#tnpForm1").attr(
          "action",
          "https://pay.basispay.in/v2/paymentrequest"
        );
        $("#tnpForm1").submit();
      });
  }
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Pay Fee Online</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Pay Fee Online</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Pay Fee Online</h2>
            <Form id="tnpForm">
              <Row>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="name1"
                      name="name1"
                      placeholder="Enter Full Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="email1"
                      name="email"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="phone1"
                      name="phone"
                      placeholder="Enter Mobile Number"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Counselor Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Counselor Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Courses</Form.Label>
                    <select
                      required
                      name="course1"
                      id="course1"
                      className="form-select form-control"
                    >
                      <CourseList />
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="amount1"
                      name="amount"
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      id="comment1"
                      name="comment1"
                      placeholder="Enter Comment"
                      rows={3}
                    />
                  </Form.Group>
                </div>

                {/* <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction Type</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Transaction Type"
                    />
                  </Form.Group>
                </div> */}
              </Row>
            </Form>

            <Form id="tnpForm1" method="POST">
              <input
                type="hidden"
                value="51f1c2bf-8654-4215-8ee4-5bf4ef779955"
                name="api_key"
              />
              <input type="hidden" value="" name="hash" />
              <input
                type="hidden"
                value="https://isbmuniversity.edu.in/api/payment_response.php"
                name="return_url"
              />
              <input type="hidden" value="LIVE" name="mode" />
              <input type="hidden" value="" name="order_id" />
              <input type="hidden" value="" name="amount" />
              <input type="hidden" value="INR" name="currency" />
              <input type="hidden" value="Payment Gateway" name="description" />
              <input type="hidden" value="" name="name" />
              <input type="hidden" value="" name="email" />
              <input type="hidden" value="" name="phone" />
              <input type="hidden" value="Lalji Pada" name="address_line_1" />
              <input
                type="hidden"
                value="Kandivali West"
                name="address_line_2"
              />
              <input type="hidden" value="Mumbai" name="city" />
              <input type="hidden" value="Maharashtra" name="state" />
              <input type="hidden" value="400051" name="zip_code" />
              <input type="hidden" value="India" name="country" />
              <input type="hidden" value="" name="udf1" />
              <input type="hidden" value="" name="udf2" />
              <input type="hidden" value="" name="udf3" />
              <input type="hidden" value="" name="udf4" />
              <input type="hidden" value="" name="udf5" />
              <Button
                variant="primary"
                onClick={(e) => getData(e)}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Onlinepayment;
