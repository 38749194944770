import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import google from "../../Images/svg/google.webp";
import whatsapp from "../../Images/svg/whatsapp.webp";
import { Link } from "react-router-dom";
import facebook from "../../Images/icons/facebook.webp";
import twitter from "../../Images/icons/twitter.webp";
import instagram from "../../Images/icons/instagram.webp";
import linkedin from "../../Images/icons/linkedin.webp";
import youtube from "../../Images/icons/youtube.webp";
import pinterest from "../../Images/icons/pinterest.webp";
import EnquiryNow from "./EnquiryNow3";

const Footer = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        setSchools(response.data.data);
        // console.log(response);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <a href="https://api.whatsapp.com/send/?phone=%2B919373199999&text&type=phone_number&app_absent=0">
        <img alt="social media links" id="whatsappp" src={whatsapp} />
      </a>
      <button
        onClick={() => setModalShow(true)}
        className="trigger_popup border-0"
      >
        <span>Enquire Now</span>
      </button>
      <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
      <div className="bg-blue py-3 mt-3">
        <Container className="Footer">
          <Row>
            <Col></Col>
          </Row>
          <Row className="justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h4 className="text-white font-weight-bold">About Us</h4>
              <ul>
                <li>
                  <Link to="/about-us">About ISBM University </Link>
                </li>
                <li>
                  <Link to="/vision-mission">Vision and Mission</Link>
                </li>
                <li>
                  <Link to="/honorable-visitor">Hon'ble Visitor</Link>
                </li>
                <li>
                  <Link to="/honorable-patron">Hon'ble Patron</Link>
                </li>
                <li>
                  <Link to="/honorable-chancellor">Hon'ble Chancellor</Link>
                </li>
                <li>
                  <Link to="/directorgeneral">Director General</Link>
                </li>
                <li>
                  <Link to="/honorable-vice-chancellor">
                    Hon'ble Vice-Chancellor
                  </Link>
                </li>
                <li>
                  <Link to="/official-registar">Registrar</Link>
                </li>

                <li>
                  <Link to="/governing-body">Governing Body</Link>
                </li>
                <li>
                  <Link to="/board-management">Board of Management</Link>
                </li>
                <li>
                  <Link to="/academic-council">Academic Council</Link>
                </li>
                <li>
                  <Link to="/board-advisor">Board of Advisors</Link>
                </li>
                <li>
                  <Link to="/other-communitie">Other Committees</Link>
                </li>
                <li>
                  <Link to="/message">Messages of the Dignitaries</Link>
                </li>
                <li>
                  <Link to="/statues-and-ordinance">
                    Statutes and Ordinances
                  </Link>
                </li>
                <li>
                  <Link to="/recognisation">Recognitions and Approvals</Link>
                </li>
                <li>
                  <Link to="/awards">Awards</Link>
                </li>
                <li>
                  <Link to="/media-coverage">Media Coverage</Link>
                </li>
                <li>
                  <Link to="/RTI">RTI</Link>
                </li>
                <li>
                  <Link to="/code-of-conduct-faculty">
                    Code of Conduct (Faculty)
                  </Link>
                </li>
                <li>
                  <Link to="/code-of-conduct-management">
                    Code of Conduct (Management)
                  </Link>
                </li>
                <li>
                  <Link to="/code-of-conduct-student">
                    Code of Conduct (Student)
                  </Link>
                </li>
                <li>
                  <Link to="/code-of-conduct-boardes-of-hostel">
                    Code of Conduct (Boardes of Hostel)
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h4 className="text-white font-weight-bold">Our Schools</h4>
              <ul>
                {schools.map((school) => (
                  <li key={school.id}>
                    <Link to={`/school-details/${school.name}`}>
                      {school.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h4 className="text-white font-weight-bold">Admissions</h4>
              <ul>
                <li>
                  <Link to="/programmes-and-eligibilities">
                    Programmes and Eligibilities
                  </Link>
                </li>
                <li>
                  <Link to="/programmes-by-qualification">
                    Programmes By Qualification
                  </Link>
                </li>
                {/* <li>
                  <Link to="/industry-integrated-programmes">
                    Industry Integrated Programmes
                  </Link>
                </li> */}
                <li>
                  <Link to="/skill-development">Skill Development</Link>
                </li>
                <li>
                  <Link to="/apply-online">Apply Online</Link>
                </li>
                {/* <li>
                  <Link to="/downloads">Download and Resources</Link>
                </li> */}

                <li>
                  <Link to="/payment-procedure-guidelines">
                    Payment Procedure Guidelines
                  </Link>
                </li>
                <li>
                  <Link to="/admission-procedure-guidelines">
                    Admission Procedure Guidelines
                  </Link>
                </li>
                <li>
                  <Link to="/pay-fees-online">Online Payment</Link>
                </li>
                <li>
                  <Link to="/admission-help-desk">Admission Helpdesk</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h4 className="text-white font-weight-bold">Academics</h4>
              <ul>
                <li>
                  <Link to="/academic-philosophy">
                    Academic Philosophy
                  </Link>
                </li>
                <li>
                  <Link to="/academic-partner">Academic Partners</Link>
                </li>
                {/* <li>
                  <Link to="/special-development-cells">
                    Special Development Cells
                  </Link>
                </li> */}
                <li>
                  <Link to="/online-resources">Online Resources</Link>
                </li>
                <li>
                  <Link to="/teaching-methodology-pedagogy">
                    Teaching Methodology/Pedagogy
                  </Link>
                </li>
                <li>
                  <Link to="/mooc-with-isbm">MOOC with ISBM University</Link>
                </li>
                {/* <li>
                  <Link to="/certificate-programmes-and-mdps-certificate-courses">
                    Certificate Programmes and MDPs
                  </Link>
                </li>
                <li>
                  <Link to="/industry-integrated">
                    Industry Integrated Programmes
                  </Link>
                </li> */}
                <li>
                  <Link to="/Research">Research</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
      <div className="bg-purple  Socials">
        <Container>
          <Row>
            <Col lg={6} className="socil sasaas mt-2 ">
              <a href="https://www.facebook.com/isbmuniversity/">
                <img alt="social media links" src={facebook} />
              </a>
              <a href="https://instagram.com/isbm_university?utm_medium=copy_link">
                <img alt="social media links" src={instagram} />
              </a>
              <a href="https://www.linkedin.com/school/13211303/admin/">
                <img alt="social media links" src={linkedin} />
              </a>
              <a href="https://twitter.com/ISBMUniversity">
                <img alt="social media links" src={twitter} />
              </a>
              <a href="https://www.youtube.com/c/ISBMUniversity">
                <img alt="social media links" src={youtube} />
              </a>
              <a href="https://www.pinterest.ca/isbmuniversity/_created/">
                <img alt="social media links" src={pinterest} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-center p-1 bg-grey">
        <Container>
          <Row>
            <Col lg={12} className="socil">
              <p>
                © Copyright{" "}
                <a href="https://isbmuniversity.edu.in/">ISBM University,</a>
                ,2016. All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Footer;
