import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import vinay from "../../Images/Hon'ble Persons/Dr. Vinay M. Agrawal.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";
const RefundPolicy = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Refund Policy</h1>
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Row>
          <Col lg={9} className="m-auto">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Refund Policy</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="linkabout123">Refund Policy</h2>

            <h5 class="fw-bold text-decoration-underline">Introduction</h5>
            <p className="text-justify">
              ISBM University is committed to providing the highest quality
              educational services. However, we understand that circumstances
              may arise where you need to request a refund for a course or
              service. This Refund Policy outlines the conditions under which
              refunds are provided.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              1. Eligibility for Refunds
            </h5>
            <p className="text-justify">
              Refunds may be granted under the following conditions:
              <ul>
                <li>
                  1.1 Course Cancellation: If ISBM University cancels a course,
                  students will receive a full refund of any fees paid.
                </li>
                <li>
                  1.2 Withdrawal Before Course Commencement: If you withdraw
                  from a course before it begins, you may be eligible for a
                  partial or full refund, depending on the timing of the
                  withdrawal.
                </li>
                <li>
                  1.3 Non-Refundable Fees: Certain fees, such as registration
                  and administrative fees, may not be refundable. Please check
                  the specific course terms for more information.
                </li>
              </ul>
            </p>
            <h5 class="fw-bold text-decoration-underline">
              2. Refund Requests
            </h5>
            <p className="text-justify">
              To request a refund, you must contact ISBM University by email at
              support@isbm.org.in with your order details and the reason for the
              refund request.
            </p>
            <p className="text-justify">
              Your refund request must include the following information:
            </p>
            <p className="text-justify">
              <ul>
                <li>Your full name</li>
                <li>Course or service purchased</li>
                <li>Order number or payment reference</li>
                <li>Reason for the refund request</li>
              </ul>
            </p>
            <h5 class="fw-bold text-decoration-underline">
              3. Processing Refunds
            </h5>
            <p className="text-justify">
              Once we receive your refund request, we will review the details
              and notify you of the approval or rejection of your request within
              [X] business days. If your refund is approved, we will process the
              refund to your original payment method.
            </p>
            <li>
              {" "}
              3.1 Timing of Refunds: Approved refunds will typically be
              processed within [X] business days from the date of approval.
              Depending on your payment method and financial institution, it may
              take additional time for the refund to reflect in your account.
            </li>
            <li>
              3.2 Deduction of Fees: If applicable, any non-refundable fees or
              charges (e.g., administrative or service fees) will be deducted
              from the refunded amount.
            </li>

            <h5 class="fw-bold text-decoration-underline mt-4">
              4. Late or Missing Refunds
            </h5>
            <p className="text-justify">
              If you have not received your refund within the expected time
              frame, please follow these steps:
            </p>
            <li>
              {" "}
              Check your bank or credit card account for any pending
              transactions.
            </li>
            <li>
              Contact your credit card company or bank as there may be a delay
              in processing your refund.
            </li>
            <li>
              If you have completed these steps and still have not received your
              refund, please contact us at info@isbmuniversity.edu.in.
            </li>
            <h5 class="fw-bold text-decoration-underline mt-4">
              5. No Refunds After Course Commencement
            </h5>
            <p className="text-justify">
              Refunds will not be provided once a course has commenced, except
              in exceptional circumstances such as medical emergencies. In such
              cases, documentation may be required, and refunds will be granted
              at the sole discretion of ISBM University.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              6. Refunds for Special Offers and Discounts
            </h5>
            <p className="text-justify">
              If you purchased a course or service at a discounted rate or as
              part of a special offer, refunds will be calculated based on the
              promotional price. Discounts applied at the time of purchase will
              not be refunded.
            </p>
            <h5 class="fw-bold text-decoration-underline">
              7. Changes to Refund Policy
            </h5>
            <p className="text-justify">
              ISBM University reserves the right to modify or update this Refund
              Policy at any time. Changes will be posted on this page, and we
              encourage you to review this policy regularly. Your continued use
              of our services constitutes acceptance of any changes to this
              policy.
            </p>
           
            <h5 class="fw-bold text-decoration-underline">
              8. Contact Information
            </h5>
            <p className="text-justify">
              If you have any questions about our Refund Policy, please contact
              us at:
            </p>
           <li>ISBM University</li>
           <li>Phone: +91 8928111111</li>
           <li>Email: support@isbm.org.in</li>
        
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default RefundPolicy;
